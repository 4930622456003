import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import env from '~/constants/env'

type Hreflang = {
  lang: string
  url: string
}
interface Props {
  title?: string
  description?: string
  structuredData?: string
  hreflang?: Hreflang[]
  canonicalLink?: string
  noIndex?: boolean
}

const Meta: React.FC<Props> = ({
  title = '',
  description = '',
  children,
  structuredData = '',
  hreflang = [],
  canonicalLink = '',
  noIndex = false
}) => {
  const router = useRouter()
  return (
    <Head>
      {title ? <title>{title}</title> : ''}
      {description ? <meta name="description" content={description} /> : ''}
      {/* og: */}
      {title ? <meta property="og:title" content={title} /> : ''}
      {description ? <meta property="og:description" content={description} /> : ''}
      <meta property="og:url" content={`https://www.joblist.com${router.asPath}`} />
      {/* twitter: */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Joblist" />
      {title ? <meta name="twitter:title" content={title} /> : ''}
      {description ? <meta name="twitter:description" content={description} /> : ''}
      <meta
        name="twitter:image"
        content={`${env.PUBLIC_ASSET_PREFIX}/static/Joblist_Logo_Black.png`}
      />
      {/* Other */}
      {children}
      {/* Structured Data */}
      {structuredData.length > 0 ? (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: structuredData
          }}
        />
      ) : null}
      {hreflang &&
        hreflang.map((ele, index) => (
          <link key={index} rel="alternate" hrefLang={ele.lang} href={ele.url} />
        ))}
      {canonicalLink ? <link rel="canonical" href={canonicalLink} /> : null}
      {noIndex ? <meta name="robots" content="noindex" /> : null}
    </Head>
  )
}

export default Meta
