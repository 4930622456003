import React from 'react'
import { css } from '@emotion/core'
import StyledLink, { StyledLinkProps } from './StyledLink'
import Icon from '../Icon'
import { BLUE } from '../../../constants/theme.styles'
import { EmotionStyles } from '~/types/types'

const arrowLinkStyles = css`
  &:hover {
    .icon-inner-fill {
      transition: fill 0.4s ease;
      fill: ${BLUE} !important;
    }
  }
`
const iconStyles = css`
  margin-left: 0.6em;
  position: relative;
  top: -1px;
  font-size: 0.8em;
`

type ArrowLinkProps = StyledLinkProps & {
  arrowCss?: EmotionStyles
}

const ArrowLink = React.forwardRef(
  ({ arrowCss, href, children, ...rest }: ArrowLinkProps, ref: React.Ref<HTMLAnchorElement>) => (
    <StyledLink ref={ref} css={arrowLinkStyles} {...rest}>
      <span>{children}</span>
      <Icon color="blue" icon="arrow" css={[iconStyles, arrowCss]} />
    </StyledLink>
  )
)

export default ArrowLink
