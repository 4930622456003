import React from 'react'
import Link from 'next/link'
import { css } from '@emotion/core'
import LazyLoad from 'react-lazyload'
import Heading from '../../common/Heading'
import Image from '../../common/Image'
import CategoryLink from './CategoryLink'
import ArrowLink from '../../common/Links/ArrowLink'
import { getCategoryColor } from './blogUtils'

const containerStyles = css`
  width: 100%;
  padding-bottom: 15px;
  position: relative;
`

const imageLink = css`
  display: block;
  width: 100%;
`

const headingStyles = css`
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 20px;
`

const tagsStyles = css`
  margin-bottom: 20px;
`

const titleLinkStyles = css`
  width: 100%;
  display: block;
  margin-top: 10px;
`
const summaryStyles = css`
  width: 100%;
  margin-bottom: 46px;
  color: #839399;
  font-size: 16px;
  line-height: 24px;
`

const contentContainerStyles = css`
  width: 100%;
  padding: 30px 15px 0 15px;
`
const readMoreLink = css`
  position: absolute;
  bottom: 15px;
`

interface PostPreviewProps {
  uri: string
  imageUrl?: string | null
  title: string
  description?: string | null
  category?: string | null
  categoryUri?: string | null
}

const PostPreview: React.FC<PostPreviewProps> = ({
  uri,
  imageUrl,
  title,
  description,
  category,
  categoryUri
}) => {
  const labelColor = getCategoryColor(category)
  const slug: string = uri.split(/\//).pop() || ''
  return (
    <div css={containerStyles}>
      {imageUrl ? (
        <Link href={{ pathname: '/article', query: { articleSlug: slug } }} as={uri} passHref>
          <a css={imageLink}>
            <LazyLoad height="60%" once>
              <Image fadeIn showLoader animateLoader heightPercent={60} src={imageUrl} />
            </LazyLoad>
          </a>
        </Link>
      ) : null}
      <div css={contentContainerStyles}>
        {category ? (
          <CategoryLink
            name={category}
            href={categoryUri}
            label={labelColor}
            size="small"
            css={tagsStyles}
          />
        ) : null}
        <Link href={{ pathname: '/article', query: { articleSlug: slug } }} as={uri} passHref>
          <a css={titleLinkStyles}>
            <Heading css={headingStyles}>{title}</Heading>
          </a>
        </Link>
        {description ? <p css={summaryStyles}>{description}</p> : null}
        <Link href={{ pathname: '/article', query: { articleSlug: slug } }} as={uri} passHref>
          <ArrowLink underline="none" color="black" css={readMoreLink}>
            Read more
          </ArrowLink>
        </Link>
      </div>
    </div>
  )
}

export default PostPreview
