import React, { useEffect } from 'react'
import { css } from '@emotion/core'
import Cookie from 'js-cookie'
import { Mixpanel } from '~/lib/mixpanel'
import { useNoticeActions } from '~/context/NoticeContext'
import { parseCookies } from '~/lib/utils/cookies'
import { useUpdateEffect } from '~/components/hooks/useUpdateEffect'
import { useFlags } from '~/context/FlagsContext'
import { BLACK, BLUE } from '~/constants/theme.styles'
import { useCookies } from '~/context/CookieContext'
import { gdprCountries } from '~/constants/gdprCountries'

const textStyles = css`
  @media only screen and (min-width: 375px) {
    display: block;
  }
`

const anchorStyles = css`
  color: ${BLACK};
  text-decoration: none;
  border-bottom: 1px solid #b9b9b9;
  padding-bottom: 1px;
  line-height: 1.45em;
  :hover {
    color: ${BLUE};
    border-color: ${BLUE};
  }
`

interface Props {
  validView?: boolean
}

const isPrivacyLocation = (cookies: { [key: string]: string | undefined }) => {
  const userCountry = cookies['X-AppEngine-Country'] || ''
  const userRegion = cookies['X-AppEngine-Region'] || ''

  const isCalifornia = userCountry.toLowerCase() === 'us' && userRegion.toLowerCase() === 'ca'
  const isGdprCountry = !!gdprCountries[userCountry.toUpperCase()]
  const isUnknownLocation = !userCountry || (userCountry.toLowerCase() === 'us' && !userRegion)

  return isCalifornia || isGdprCountry || isUnknownLocation
}

const PrivacyNotification = ({ validView = true }: Props) => {
  const { createNotice } = useNoticeActions()
  const flags = useFlags()
  const cookies = useCookies()

  const getCurrentViewCount = () => {
    return parseInt(parseCookies().privacyNotice || '0')
  }

  const confirmAsSeen = () => {
    const newViewCount = getCurrentViewCount() + 1
    if (newViewCount > 1) {
      return
    }
    Cookie.set('privacyNotice', newViewCount.toString(), {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    })
  }

  const currentViewCount = getCurrentViewCount()

  const showPrivacyNotice =
    isPrivacyLocation(cookies) && flags.showPrivacyNotice && currentViewCount === 0

  useEffect(() => {
    if (showPrivacyNotice) {
      createNotice(
        <div>
          <span css={textStyles}>By using Joblist you consent to use of cookies,</span>
          <span css={textStyles}>
            {' '}
            our{' '}
            <a
              href="/terms"
              css={anchorStyles}
              target="_blank"
              onClick={() => {
                Mixpanel.track('Privacy Banner - TOS Click', {})
              }}
            >
              Terms of Use
            </a>{' '}
            and{' '}
            <a
              href="/privacy"
              css={anchorStyles}
              target="_blank"
              onClick={() => {
                Mixpanel.track('Privacy Banner - PP Click', {})
              }}
            >
              Privacy Policy
            </a>
            .
          </span>
        </div>,
        {
          id: 'privacyNotice',
          color: 'light',
          onOpen: () => {
            Mixpanel.track('Privacy Banner - View', {})
          },
          onClose: () => {
            Mixpanel.track('Privacy Banner - Exit Click', {})
            confirmAsSeen()
          }
        }
      )
    }
    if (validView && showPrivacyNotice) {
      confirmAsSeen()
    }
  }, [])

  useUpdateEffect(() => {
    if (validView && showPrivacyNotice) {
      confirmAsSeen()
    }
  }, [validView, showPrivacyNotice])

  return null
}

export default PrivacyNotification
